const teamTryoutVarArray = [
  {
    teamTryoutId: 'teamTryout2022',
    teamTryoutName: 'Team Tryouts Spring 2022',
    teamTryoutDates: ['04-10-2021'],
    teamTryoutDatesFull: 'May 2022',
    teamTryoutDescription: '',
    teamTryoutYear: 2022,
    teamTryoutLocation: 'Ice Factory, Orlando, Florida',
    teamTryoutDivisions: ['8U', '10U', '12U', '14U', '16U', '18U'],
    seasonYear: '2022-2023',
    paypalButtonId: '23PS943GUFBPS',
    paypalButtonIdDev: 'SP3D3FGXYTCHL',
    optionsTitle: 'Sessions',
    options: [
      {
        optionId: 1,
        quantity: 1,
        raw: '1+Session',
        description: 'Single Session (8U, 10U, 12U, 18U Only)',
        total: '$45',
        totalNum: 45,
        totalNumString: '45'
      },
      {
        optionId: 2,
        quantity: 1,
        raw: '1+Session+Skills',
        description: 'Skills Session (14U and 16U Only)',
        total: '$45',
        totalNum: 45,
        totalNumString: '45'
      },
      {
        optionId: 3,
        quantity: 1,
        raw: '1+Session+Scrimmage',
        description: 'Scrimmage Session (14U and 16U Only)',
        total: '$45',
        totalNum: 45,
        totalNumString: '45'
      },
      {
        optionId: 4,
        quantity: 1,
        raw: '2+Sessions',
        description: 'Skills and Scrimmage (14U and 16U Only)',
        total: '$80',
        totalNum: 80,
        totalNumString: '80'
      }
    ],
    retired: false,
    hidden: false
  },
  {
    teamTryoutId: 'teamTryout2023',
    teamTryoutName: 'Team Tryouts Spring 2023',
    teamTryoutDates: ['05-02-2023'],
    teamTryoutDatesFull: 'May 2023',
    teamTryoutDescription: '',
    teamTryoutYear: 2023,
    teamTryoutLocation: 'Ice Factory, Orlando, Florida',
    teamTryoutDivisions: ['8U', '10U', '12U', '14U', '16U', '18U'],
    seasonYear: '2023-2024',
    paypalButtonId: '98M256QCDVS5S',
    paypalButtonIdDev: 'FC6GY6ZZM2HHS',
    optionsTitle: 'Sessions',
    options: [
      {
        optionId: 1,
        quantity: 1,
        raw: 'Tuesday+10U+12U+16U',
        description: 'Tuesday 5/2 (10U, 12U, 16U)',
        total: '$55',
        totalNum: 55,
        totalNumString: '55',
        // leave the second date expiration field alone -- it is automatically set during the build process.
        // set only the first human-readable date field.
        expireDateFull: '05/01/2023 11:59:00 pm',
        expireDateParsed: 1682985540000,
      },
      {
        optionId: 2,
        quantity: 1,
        raw: 'Wednesday+8U',
        description: 'Wednesday 5/3 (8U)',
        total: '$35',
        totalNum: 35,
        totalNumString: '35',
        // leave the second date expiration field alone -- it is automatically set during the build process.
        // set only the first human-readable date field.
        expireDateFull: '05/02/2023 11:59:00 pm',
        expireDateParsed: 1683071940000,
      },
      {
        optionId: 3,
        quantity: 1,
        raw: 'Wednesday+14U',
        description: 'Wednesday 5/3 (14U)',
        total: '$55',
        totalNum: 55,
        totalNumString: '55',
        // leave the second date expiration field alone -- it is automatically set during the build process.
        // set only the first human-readable date field.
        expireDateFull: '05/02/2023 11:59:00 pm',
        expireDateParsed: 1683071940000,
      },
      {
        optionId: 4,
        quantity: 1,
        raw: 'Thursday+8U',
        description: 'Thursday 5/4 (8U)',
        total: '$35',
        totalNum: 35,
        totalNumString: '35',
        // leave the second date expiration field alone -- it is automatically set during the build process.
        // set only the first human-readable date field.
        expireDateFull: '05/03/2023 11:59:00 pm',
        expireDateParsed: 1683158340000,
      },
      {
        optionId: 5,
        quantity: 1,
        raw: 'Thursday+10U+12U+14U+16U',
        description: 'Thursday 5/4 (10U, 12U, 14U, 16U)',
        total: '$55',
        totalNum: 55,
        totalNumString: '55',
        // leave the second date expiration field alone -- it is automatically set during the build process.
        // set only the first human-readable date field.
        expireDateFull: '05/03/2023 11:59:00 pm',
        expireDateParsed: 1683158340000,
      }
    ],
    retired: true,
    hidden: true
  }, {
    teamTryoutId: 'teamTryout202314UA2',
    teamTryoutName: 'Team Tryouts Spring 2023 14UA2',
    teamTryoutDates: ['05-10-2023'],
    teamTryoutDatesFull: 'May 2022',
    teamTryoutDescription: '',
    teamTryoutYear: 2023,
    teamTryoutLocation: 'Ice Factory, Kissimmee, Florida',
    teamTryoutDivisions: ['14U'],
    seasonYear: '2023-2024',
    paypalButtonId: 'NSLYYQJEZHQ7G',
    paypalButtonIdDev: 'V94C84MT5JV28',
    optionsTitle: 'Sessions',
    options: [
      {
        optionId: 1,
        quantity: 1,
        raw: '14UA2',
        description: '14UA2',
        total: '$37',
        totalNum: 37,
        totalNumString: '37'
      }
    ],
    retired: true,
    hidden: true
  },
  {
    teamTryoutId: 'yetisTryout2024',
    teamTryoutName: 'Yetis Tryouts 2024',
    teamTryoutDates: ['01-01-2024'],
    teamTryoutDatesFull: 'January 1st 2024',
    teamTryoutDescription: '8:05-9:05\n' +
      'Bantam + (2009/2010/2011)\n' +
      '\n' +
      '9:15-10:15\n' +
      'Pewee (2012/2013)\n' +
      '\n' +
      '10:25-11:25\n' +
      'Squirt (2014/2015)\n' +
      '\n' +
      '11:35-12:35\n' +
      'Pewee (2012/2013)\n' +
      '\n' +
      '12:45-1:45\n' +
      'Squirt (2014/2015)',
    teamTryoutYear: 2024,
    teamTryoutLocation: 'Ice Factory, Orlando, Florida',
    teamTryoutDivisions: ['10U', '12U', '14U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'UDSCF6CZXAM9C',
    paypalButtonIdDev: 'Q5K5BSJ4M5X82',
    optionsTitle: 'Cost',
    totalNumString: '40',
    total: '$40',
    totalNum: 40,
    retired: false,
    hidden: false
  },
  {
    teamTryoutId: 'yetisPractice2024',
    teamTryoutName: 'Yetis Practice 2024',
    teamTryoutDates: ['01-15-2024'],
    teamTryoutDatesFull: 'Dates TBD',
    teamTryoutDescription: '',
    teamTryoutYear: 2024,
    teamTryoutLocation: 'Location TBD',
    teamTryoutDivisions: ['10U', '12U', '14U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'U42MNM9LTEBCG',
    paypalButtonIdDev: 'T3SR4CQ4476YQ',
    optionsTitle: 'Cost',
    totalNumString: '802',
    total: '$802',
    totalNum: 802,
    retired: true,
    hidden: true,
    practice: true,
    expireDateFull: '01/01/2024 11:59:00 pm',
    expireDateParsed: 1704153540000,
  },
  {
    teamTryoutId: 'teamTryout2024',
    teamTryoutName: 'Team Tryouts Spring 2024',
    teamTryoutDates: ['04-29-2024','04-30-2024','05-01-2024','05-02-2024'],
    teamTryoutDatesFull: 'April 29th through May 2nd 2024',
    teamTryoutDescription:  '14U - 04/30 6:45pm-7:50pm and 05/02 7:10pm-8:25pm\n' +
    '16U - 04/30 8:00pm-9:05pm and 05/01 6:55pm-8:10pm\n',
    teamTryoutYear: 2024,
    teamTryoutLocation: 'Ice Factory, Orlando, Florida',
    teamTryoutDivisions: ['14U', '16U'],
    seasonYear: '2024-2025',
    paypalButtonId: 'D6EEF2VWRZ262',
    paypalButtonIdDev: '',
    optionsTitle: 'Sessions',
    options: [
      {
        optionId: 1,
        quantity: 1,
        raw: '10U+12U+14U+16U',
        description: '14U or 16U',
        total: '$78',
        totalNum: 78,
        totalNumString: '78',
        expireDateFull: '06/01/2024 11:59:00 pm',
        expireDateParsed: 1717286340000,
      }
    ],
  }
];

export default teamTryoutVarArray;
